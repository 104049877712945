import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Landing Page</title>
        <meta property="og:title" content="Landing Page" />
      </Helmet>
      <div data-role="Header" className="home-navbar-container">
        <div className="home-navbar">
          <div className="home-left-side">
            <img
              alt="image"
              src="/playground_assets/twitter_c72dl8pd_400x400-400h.jpg"
              className="home-image"
            />
            <div data-role="BurgerMenu" className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
          <div className="home-right-side">
            <a href="#contact"><button className="home-cta-btn Anchor button">Contact us</button></a>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-container1">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className="home-image1"
              />
              <div data-role="CloseMobileMenu" className="home-close-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-links-container">
              <a href="#resources" className="home-link Anchor">
                Resources
              </a>
              <span className="home-link1 Anchor">Inspiration</span>
              <a href="#process" className="home-link2 Anchor">
                Process
              </a>
              <span className="home-link3 Anchor">Our story</span>
            </div>
          </div>
        </div>
      </div>
      <script>

      </script>
      <div id="resources" className="home-hero">
        <div className="home-content-container">
          <div className="home-hero-text">
            <h1 className="home-heading">
              <span className="home-text" onMouseOver={randomize} id="eyekon">EYEKON</span>
              <br className="home-text01"></br>
              <span className="home-text02">Innovation Through Connection</span>
              <br></br>
            </h1>
            <a href="#contact"><button className="home-cta-btn1 Anchor button" href="#contact">Contact us</button></a>
          </div>
        </div>
      </div>
      <div className="home-team">
        <div className="home-heading-container">
          <h1 className="home-text04 Section-Heading">Meet the Team</h1>
        </div>
        <div className="home-cards-container">
          <div className="home-team-card">
            <img
              alt="image"
              src="/playground_assets/orange-300h.jpg"
              className="home-image2"
            />
            <a
              href="https://twitter.com/Orange0rangutan"
              target="_blank"
              rel="noreferrer noopener"
              className="home-name"
            >
              <span>Orange</span>
              <span className="home-text06 Card-Heading">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </a>
            <span className="home-position Card-Text">COO and Founder</span>
          </div>
          <div className="home-team-card1">
            <img
              alt="image"
              src="/playground_assets/twitter_q5tpljx-_400x400-300h.jpg"
              className="home-image3"
            />
            <a
              href="https://twitter.com/crouserrr"
              target="_blank"
              rel="noreferrer noopener"
              className="home-name1"
            >
              <span>Kin</span>
              <span>gDigi</span>
            </a>
            <span className="home-position1 Card-Text">CEO and Founder</span>
          </div>
          <div className="home-team-card2">
            <img
              alt="image"
              src="/playground_assets/ac3-300h.png"
              className="home-image4"
            />
            <a
              href="https://twitter.com/ACthreeNFT"
              target="_blank"
              rel="noreferrer noopener"
              className="home-name2 Card-Heading"
            >
              AC Three
            </a>
            <span className="home-position2">
              <span className="Card-Text">Head of Community</span>
              <br></br>
              <span>Management </span>
              <br></br>
            </span>
          </div>
          <div className="home-team-card3">
            <img
              alt="image"
              src="/playground_assets/zhion-300h.png"
              className="home-image5"
            />
            <a
              href="https://twitter.com/zhion_sol"
              target="_blank"
              rel="noreferrer noopener"
              className="home-name3"
            >
              <span className="home-text13 Card-Heading">Zhion</span>
              <br></br>
            </a>
            <span className="home-position3 Card-Text">Head discord admin</span>
          </div>
          <div className="home-team-card4">
            <img
              alt="image"
              src="/playground_assets/twitter_g0orxipn_400x400-300h.jpg"
              className="home-image6"
            />
            <a
              href="https://twitter.com/seanmsuvie"
              target="_blank"
              rel="noreferrer noopener"
              className="home-name4 Card-Heading"
            >
              SMS
            </a>
            <span className="home-position4 Card-Text">
              <span>
                Head of content
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>creation</span>
            </span>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="studio" className="home-services">
        <div className="home-heading-container1">
          <h1 className="home-text18">
            <span className="Section-Heading">Our Studios</span>
            <br></br>
          </h1>
        </div>
        <div className="home-copperhead">
          <img
            alt="image"
            src="/playground_assets/clearback.png"
            className="home-image7"
          />
          <div className="home-container69">
            <a href="https://copperheadsoftware.co/"><h1 className='CSlink'>Copperhead Software</h1></a>
            <h3>Copperhead Software is out SaaS and Development branch with a small group of developers building amazing products to revolutionize the space.</h3>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="contact" className="home-contact">
        <div className="home-container2">
          <h1>
            <span className="Section-Heading">Contact us!</span>
            <br></br>
            <br></br>
          </h1>
          <span className="home-text30">
            <span className="home-text31">Twitter:</span>
            <br className="home-text32"></br>
            <a
              href="https://twitter.com/EYEKONNFT"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link6"
            >
              @EYEKONNFT
            </a>
            <br className="home-text33"></br>
            <br className="home-text34"></br>
            <span className="home-text35">Email:</span>
            <br className="home-text36"></br>
            <u><span className="home-text37"><a href='mailto:service@eyekon.xyz'>service@eyekon.xyz</a></span></u>
            <br className="home-text38"></br>
            <br className="home-text39"></br>
            <br></br>
          </span>
        </div>
      </div>
      <div className="home-footer-container">
        <div className="home-footer">
          <div className="home-social-links">
            <a
              href="https://twitter.com/EYEKONNFT"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link7"
            >
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon4">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </a>
          </div>
          <div className="home-copyright-container">
            <svg viewBox="0 0 1024 1024" className="home-icon6">
              <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"></path>
            </svg>
            <span className="Anchor">Copyright, 2023</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
